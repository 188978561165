import React, { useState, useEffect } from "react";
import {
	getAuth,
	User,
	updateProfile,
	sendEmailVerification,
} from "firebase/auth";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
	Button,
	TextField,
	Typography,
	Box,
	Avatar,
	Alert,
	Divider,
} from "@mui/material";

interface ProfileEditorProps {
	user: User | null | undefined;
}

const ProfileEditor: React.FC<ProfileEditorProps> = ({ user }) => {
	const [displayName, setDisplayName] = useState("");
	const [photoFile, setPhotoFile] = useState<File | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		if (user) {
			setDisplayName(user.displayName || "");
		}
	}, [user]);

	const handleSubmit = async () => {
		if (user) {
			setIsSubmitting(true);
			let photoURL = "";
			if (photoFile) {
				const storage = getStorage();
				const filePath = `users/${user.uid}/profile/${photoFile.name}`;
				const storageRef = ref(storage, filePath);
				await uploadBytes(storageRef, photoFile);
				photoURL = await getDownloadURL(storageRef);
			}

			await updateProfile(user, { displayName, photoURL });
			setIsSubmitting(false);
		}
	};

	const handleSendVerificationEmail = async () => {
		if (user) {
			await sendEmailVerification(user);
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files && event.target.files[0];
		if (file) {
			setPhotoFile(file);
		}
	};

	if (!user) {
		return null;
	}

	return (
		<Box>
			{!user.emailVerified && (
				<Alert severity="warning">
					Your email is not verified. Please verify your email to
					fully use all features.
					<Button
						onClick={handleSendVerificationEmail}
						disabled={isSubmitting}
					>
						Send Verification Email
					</Button>
				</Alert>
			)}

			<Typography sx={{mt:4}} variant="h5">Update Profile</Typography>

			<Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
				<Typography variant="h6">Change Display Name</Typography>

				<TextField
					label="Display Name"
					value={displayName}
					onChange={(e) => setDisplayName(e.target.value)}
					fullWidth
					margin="normal"
				/>

				<Divider sx={{ mt: 4, mb: 4 }} />
				<Typography variant="h6">Change Picture</Typography>

				<input
					accept="image/*"
					style={{ display: "none" }}
					id="upload-photo"
					type="file"
					onChange={handleFileChange}
				/>
				<label htmlFor="upload-photo">
					<Button
						variant="contained"
            sx={{mt:3}}
						color="secondary"
						component="span"
					>
						Select Profile Image
					</Button>
				</label>
			
					{user.photoURL && (
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								my: 2,
							}}
						>
							<Avatar
								src={user.photoURL}
								sx={{ width: 100, height: 100 }}
							/>
						</Box>
					)}
		

				<Divider sx={{ mt: 4, mb: 4 }} />

				<Box sx={{ mt: 3 }}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={isSubmitting}
					>
						Update Profile
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default ProfileEditor;
