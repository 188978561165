import { Box, Card, CardMedia } from '@mui/material';
import React from 'react';

interface ImageDisplayProps {
  imageUrl: string;
}

const ImageDisplay: React.FC<ImageDisplayProps> = ({ imageUrl }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ maxWidth: 600 }}>
        <CardMedia component="img" src={imageUrl} alt="Displayed Image" />
      </Card>
    </Box>
  );
};

export default ImageDisplay;
