import { connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import PromptApp from "./PromptApp";
import { EventBusProvider } from "./eventbus/eventbus";
import { auth, db, functions } from "./firebase";
import reportWebVitals from "./reportWebVitals";
import { UserAuthContextProvider } from "./utils/UserAuthContext";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);



let local = process.env.REACT_APP_LOCAL === "true";

console.log(local);
if (local) {
	console.log("Connecting to local emulators");
	connectFunctionsEmulator(functions, "localhost", 5001);
	connectAuthEmulator(auth, "http://localhost:9099");
	connectFirestoreEmulator(db, "localhost", 8082);
}

root.render(
	<UserAuthContextProvider>
		<EventBusProvider>
			<BrowserRouter>
				
					<PromptApp />
				
				
			</BrowserRouter>
		</EventBusProvider>
	</UserAuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
