import { useNavigate } from "react-router";
import { useUserAuth } from "../../utils/UserAuthContext";


const Validate = () => {
	const {  user } = useUserAuth();

	const navigate = useNavigate();

    if (!user) {
        console.log("User is not signed in.");
        navigate("/");
    }

    return <></>;
}

export default Validate;
