import React, { useEffect, useState } from "react";

import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import { LeftArrow, RightArrow } from "./PromptArrow";

// NOTE: embrace power of CSS flexbox!
import {
	collection,
	getDocs,
	limit,
	orderBy,
	query,
	where,
} from "firebase/firestore";
import "./hideScrollbar.css";
import usePreventBodyScroll from "./usePreventBodyScroll";

import { ExpandMore } from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	CircularProgress,
	Container,
	Fade,
	LinearProgress,
	Slide,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { db } from "../../../firebase";
import { PromptDisplay } from "../PromptDisplay";
import PromptCard from "./PromptCard";
import PromptCardMini from "./PromptCardMini";
import { isMobile } from "react-device-detect";
import ContentLoader, { Facebook } from "react-content-loader";
import HorizontalScroller from "../../global/HorizontalScroller";

// import "./firstItemMargin.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export default function PromptDeck({
	title,
	category="",
	isSmall,
}: {
	title: string;
	category: string;
	isSmall: boolean | undefined;
}) {
	const [items, setItems] = useState<PromptDisplay[]>([]);
	const [loading, setLoading] = useState(true);
	const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");
	useEffect(() => {
		(async () => {
			var g: PromptDisplay[] = [];
			setLoading(true);
			var q =
				category !== ""
					? query(
							collection(db, "prompts"),
							where("category", "==", category),
							orderBy("order"),
							limit(10)
					  )
					: query(
							collection(db, "prompts"),
							orderBy("order"),
							limit(10)
					  );
			const querySnapshot = await getDocs(q);
			console.log(querySnapshot.docs.length);
			querySnapshot.forEach((doc) => {
				g.push({ id: doc.id, ...doc.data() } as PromptDisplay);
			});

			setItems(g);
			setLoading(false);
		})();
	}, [items.length, category]); // Empty dependency array to avoid infinite loop

	const { disableScroll, enableScroll } = usePreventBodyScroll();

	const onWheel = (
		apiObj: scrollVisibilityApiType,
		ev: React.WheelEvent
	): void => {
		const isThouchpad =
			Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

		if (isThouchpad) {
			ev.stopPropagation();
			return;
		}

		if (ev.deltaY < 0) {
			apiObj.scrollNext();
		} else if (ev.deltaY > 0) {
			apiObj.scrollPrev();
		}
	};

	const stc = require("string-to-color");

	return (
		<Box
			className={isSmall ? "" : isDarkModeEnabled?"glassDark":"glass"}
			sx={{ mt: 2, boxShadow: 0, borderRadius: 2, mb: isSmall ? 0 : 2 }}
		>
			<Accordion
				defaultExpanded={true}
				sx={{
					background: "transparent",
					borderRadius: 0,
					boxShadow: "none",
					elevation: 0,
				}}
			>
				{!isSmall && (
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls="panel1a-content"
						id="panel1a-header"
						sx={{ background: "transparent" }}
					>
						<Box display="flex" width={1}>
							<Typography variant="h5">{title}</Typography>
						</Box>
					</AccordionSummary>
				)}
				<AccordionDetails
					sx={{ padding: 0, background: "transparent" }}
				>
					{loading && <Box sx={{p:3}}><Facebook animate={true} /></Box>}
					{!loading && (
						<Box
							onMouseEnter={disableScroll}
							onMouseLeave={enableScroll}
							sx={{
								border: 0,
								pt: 1,
								minHeight: isSmall ? 20 : 220,
								overflow:"auto",
								maxWidth:1, 
								background: "transparent",
							}}
						>
							
								<HorizontalScroller
								>
									{items.map((prompt, id) =>
										isSmall ? (
											<PromptCardMini
												prompt={prompt}
												itemId={id.toString()} // NOTE: itemId is required for track items
												key={id}
											/>
										) : (
											<Slide
												timeout={{
													enter: isMobile
														? 0
														: 500 * id,
												}}
												in={true}
												direction="left"
												key={"fade" + id.toString()}
												mountOnEnter
												unmountOnExit
											>
												<Box>
													<PromptCard
														prompt={prompt}
														itemId={id.toString()} // NOTE: itemId is required for track items
														key={id}
													/>
												</Box>
											</Slide>
										)
									)}
								</HorizontalScroller>
						
						</Box>
					)}
				</AccordionDetails>
			</Accordion>
		</Box>
	);
}
