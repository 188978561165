import { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	Typography,
	Box,
	TableContainer,
	TableRow,
	Pagination,
	Avatar,
	Button,
	LinearProgress,
	Paper,
} from "@mui/material";
import Moment from "react-moment";
import {
	collection,
	getDocs,
	orderBy,
	query,
	limit,
	DocumentData,
	startAfter,
	getCountFromServer,
	where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Folder } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../utils/UserAuthContext";
import ContentLoader from "react-content-loader";
import PromptDeck from "../prompts/list/PromptDeck";
import PromptTable from "../prompts/list/PromptTable";
import { useEventBus } from "../../eventbus/eventbus";
const stc = require("string-to-color");

interface Sort {
	field: string;
	order: "asc" | "desc";
}

const FolderList = ({
	search,
	handleClick,
}: {
	search: string;
	handleClick: (p: any) => void;
}) => {
	const [folders, setFolders] = useState<any[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [lastVisible, setLastVisible] = useState<DocumentData | undefined>();
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const navigate = useNavigate();
	const [error, setError] = useState<string>("");
	const eventBus=useEventBus();

	const [loading, setLoading] = useState(true);
	const { user } = useUserAuth();

	const [sort, setSort] = useState<Sort>({
		field: "createdAt",
		order: "asc",
	});

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const promptsRef = collection(db, "folders");

			try {
				let q =
					page === 1
						? query(
								promptsRef,
								orderBy("createdAt"),
								limit(rowsPerPage),
								where("creatorId", "==", user?.uid)
						  )
						: query(
								promptsRef,
								orderBy("createdAt"),
								startAfter(lastVisible),
								limit(rowsPerPage),
								where("creatorId", "==", user?.uid)
						  );

				const querySnapshot = await getDocs(q);
				const snapshot = await getCountFromServer(q);
				const count = await snapshot.data().count;
				let int = Math.trunc;
				setPageCount(int(count / rowsPerPage + 1));
				const data = querySnapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));
				setFolders(data);
				setLoading(false);
				setLastVisible(
					querySnapshot.docs[querySnapshot.docs.length - 1]
				);
				setError("");
			} catch (error: any) {
				setError(error.message);
				setLoading(false);
			}
		};

		fetchData();
	}, [searchTerm, page, user, rowsPerPage, sort]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
		setPage(1);
		setLastVisible(undefined);
	};

	const handleChangePage = (
		event: React.ChangeEvent<unknown> | null,
		newPage: number
	) => {
		setPage(newPage);
	};

	return (
		<Box sx={{ mt: 2 }}>
			{loading && (
				<Box sx={{ p: 2 }}>
					<ContentLoader animate={true} />
				</Box>
			)}

			{!loading && (
				<TableContainer sx={{ width: 1 }}>
					<Table>
						<TableBody>
							{folders.map((folder) => (
								<TableRow
									component={Paper}
									sx={{ borderRadius: 2, mt: 1 }}
									className="glass"
									key={folder.id}
								>
									<TableCell>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Avatar
												sx={{
													fontWeight: "bold",
													border: 1,
													borderColor: "#eee",
													background: "#777",
												}}
												aria-label="recipe"
											>
												<Folder />
											</Avatar>
											<Button
												variant="text"
												color="primary"
												onClick={() => {
													navigate(
														"/folders/" +
															folder.name
													);
												}}
											>
												<Typography
													variant="body1"
													sx={{ ml: 1 }}
												>
													{folder.name}
												</Typography>
											</Button>
										</Box>
									</TableCell>
									<TableCell
										sx={{
											display: {
												xs: "none",
												lg: "table-cell",
											},
										}}
									>
										<Typography
											variant="body2"
											sx={{ ml: 1 }}
										>
											{folder.description}
										</Typography>
									</TableCell>

									<TableCell
										sx={{
											display: {
												xs: "none",
												md: "table-cell",
												minWidth: 110,
											},
										}}
									>
										<Moment
											fromNow
											date={
												new Date(
													folder.createdAt.toDate()
												)
											}
										/>
									</TableCell>
									<TableCell>
										<Box
											display="flex"
											style={{ marginBottom: "auto" }}
											justifyContent="center"
											sx={{
												pt: 0,
											}}
										>
											<Button
												variant="outlined"
												color="primary"
												size="small"
												onClick={() => {}}
												sx={{ borderRadius: 2 }}
											>
												<Typography
													sx={{}}
													variant="caption"
												>
													{" "}
													Share
												</Typography>
											</Button>
										</Box>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}

			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					p: 1,
					mt: 0,
				}}
			>
				{folders.length > 0 && pageCount > 1 && (
					<Pagination
						count={pageCount}
						page={page}
						onChange={handleChangePage}
					/>
				)}
			</Box>
			{folders.length === 0 && (
				<Box>
					<Box sx={{ mb: 1, mt: 2, ml: 2 }}>
						<Typography variant="caption">
							You don't have any folders yet. Run an agent to create a document first!
						</Typography>
					</Box>
				

					<PromptTable
						category={""}
						handleClick={(p) => {
							eventBus.emit({
								type: "show_create_dialog",
								payload: p,
							});
						}}
					/>
				</Box>
			)}
		</Box>
	);
};

export default FolderList;
