import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
	Alert,
	Button,
	TextField,
	Stack,
	Link,
	Typography,
	Box,
	Divider,
} from "@mui/material";

import { useUserAuth } from "../../utils/UserAuthContext";
interface SignupProps {}

const Signup: React.FC<SignupProps> = () => {
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [password, setPassword] = useState("");
	const { signUp } = useUserAuth();
	let navigate = useNavigate();

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setError("");
		try {
			await signUp(email, password);
			navigate("/home");
		} catch (err: any) {
			setError(
				"Sorry, Please Check Your Email and Password. Already Have An Account? Click the below 'Log In' link"
			);
			console.log(err);
		}
	};

	return (
		<>
			<Box
				boxShadow={3}
				sx={{
					p: 4,
					mt: 3,
					border: 0,
					borderRadius: "borderRadius",
					minWidth: 300,
					maxWidth: 300,
				}}
			>
				<Typography variant="h5" sx={{ mb: 1 }}>
					🤖 New To Copilotr?
				</Typography>
				<Typography variant="caption" sx={{ mb: 2 }}>
					Join Today For Free
				</Typography>
				<Divider sx={{ mb: 2, mt: 1 }} />
				{error && <Alert severity="error">{error}</Alert>}
				<Box component="form" onSubmit={handleSubmit}>
					<Box sx={{ mt: 3 }}>
						<TextField
							label="Email address"
							variant="filled"
							fullWidth
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => setEmail(e.target.value)}
						/>
					</Box>
					<Box sx={{ mt: 3 }}>
						<TextField
							label="Password"
							type="password"
							variant="filled"
							fullWidth
							onChange={(
								e: React.ChangeEvent<HTMLInputElement>
							) => setPassword(e.target.value)}
						/>
					</Box>
					<Box sx={{ mt: 3 }}>
						<Button variant="contained" type="submit" fullWidth>
							Sign up
						</Button>
					</Box>
				</Box>
				<Box
					sx={{
						p: 2,
						pt: 4,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Typography>Alread a member? &nbsp;</Typography> <br />
					<Link component={RouterLink} to="/signin">
						<Typography>Log In</Typography>
					</Link>
				</Box>
			</Box>
		</>
	);
};

export default Signup;
