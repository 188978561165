
import {
  Chip,
} from "@mui/material";
import { useState } from "react";
import { PromptDisplay } from "../PromptDisplay";
import { useEventBus } from "../../../eventbus/eventbus";


export default function PromptCardMini({
  prompt,
}: {
  prompt: PromptDisplay;
  itemId: string; 
}) {
 
  const eventBus=useEventBus();

  return (
    <Chip
      label={prompt.icon + " " + prompt.title}
      sx={{
        margin: "0.5rem",
      }}
    
      variant="outlined"
      onClick={()=>
        {
          eventBus.emit(
            {
              type:"show_create_dialog",
              payload:prompt
            }
          )

        } }

      
    ></Chip>
  );
}
