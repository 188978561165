// HorizontalScroller.tsx
import React, { useRef, useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { styled } from '@mui/system';

const ScrollerRoot = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const Scroller = styled(Box)`
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none; // For Firefox
  -ms-overflow-style: none; // For Internet Explorer and Edge
  &::-webkit-scrollbar {
    display: none; // For Chrome, Safari, and Opera
  }
`;

const ScrollerButton = styled(Button)`
  position: absolute;
  z-index: 1;
  height:100%;
  top: 50%;
  boxShadow:10;
  transform: translateY(-50%);
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: 10;
`;

const LeftButton = styled(ScrollerButton)`
  left: ${({ theme }) => theme.spacing(0.0)};
`;

const RightButton = styled(ScrollerButton)`
  right: ${({ theme }) => theme.spacing(0.0)};
`;

interface HorizontalScrollerProps {
  children: React.ReactNode;
}

const HorizontalScroller: React.FC<HorizontalScrollerProps> = ({ children }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState('99%');
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (rootRef.current && rootRef.current.parentElement) {
        setWidth(`${rootRef.current.parentElement.clientWidth * 0.99}px`);
      }
    };

    if (rootRef.current) {
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(rootRef.current.parentElement as Element);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollerRef.current) {
        const { scrollLeft, clientWidth, scrollWidth } = scrollerRef.current;
        setShowLeftButton(scrollLeft > 0);
        setShowRightButton(scrollLeft + clientWidth < scrollWidth);
      }
    };

    if (scrollerRef.current) {
      scrollerRef.current.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check

      return () => {
        scrollerRef.current?.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const scroll = (scrollOffset: number) => {
    if (scrollerRef.current) {
      scrollerRef.current.scrollBy({
        left: scrollOffset,
        behavior: 'smooth',
      });
    }
  };

  const handleMouseWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    e.preventDefault();
    scroll(e.deltaY);
  };

  return (
    <ScrollerRoot ref={rootRef} sx={{ width }}>
      {showLeftButton && <LeftButton className='arrow'  onClick={() => scroll(-250)}>{'<'}</LeftButton>}
      <Scroller ref={scrollerRef} onWheel={handleMouseWheel}>
        {children}
      </Scroller>
      {showRightButton && <RightButton className='arrow' onClick={() => scroll(250)}>{'>'}</RightButton>}
      </ScrollerRoot>
);
};

export default HorizontalScroller;