import { Send } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEventBus } from "../../../eventbus/eventbus";
import { getCallableFunction } from "../../../firebase";
import FilePicker from "../../commen/FilePicker";
import TextInputField from "../../commen/TextInputField";
import FolderPicker from "../../folders/FolderPicker";
import { Input, InputValue, Option } from "../Prompt";
import { PromptDisplay } from "../PromptDisplay";

interface DynamicFormProps {
  prompt: PromptDisplay;
  open: boolean;
  onClose: (result: InputValue[]) => void;
}

const runPrompt = getCallableFunction("runPrompt");

export const DynamicForm: React.FC<DynamicFormProps> = ({
  prompt,
  open,
  onClose,
}) => {
  const [error, setError] = useState<any>("");
  const [folder, setFolder] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const inputs = prompt.inputs;

  const [inputValues, setInputValues] = useState<InputValue[]>(() => {
    return inputs.reduce<InputValue[]>((acc, input) => {
      if (input.defaultValue != null) {
        return acc.concat({ name: input.name, value: input.defaultValue });
      }
      return acc;
    }, []);
  });

  const eventbus = useEventBus();
  const navigate = useNavigate();

  const handleInputChange = (name: string, value: string) => {
    setInputValues((prev) => {
      const index = prev.findIndex((item) => item.name === name);
      if (index >= 0) {
        prev[index].value = value;
      } else {
        prev.push({ name, value });
      }
      return [...prev];
    });
  };

  // const handleInputDefaultValues = (inputs: Input[]) => {
  //   // const defaultValues = inputs.reduce<InputValue[]>((acc, input) => {
  //   //   if (input.defaultValue != null) {
  //   //     const index = inputValues.findIndex(item => item.name === input.name);
  //   //     if (index >= 0) {
  //   //       return acc.concat(inputValues[index]);
  //   //     } else {
  //   //       return acc.concat({ name: input.name, value: input.defaultValue });
  //   //     }
  //   //   }
  //   //   return acc;
  //   // }, []);

  //   const defaultValues = inputs.reduce<InputValue[]>((acc, input) => {
  //     if (input.defaultValue != null) {
  //       return acc.concat({ name: input.name, value: input.defaultValue });
  //     }
  //     return acc;
  //   }, []);

  //   if (defaultValues.length > 0) {
  //     setInputValues((prevInputValues) => [
  //       ...prevInputValues,
  //       ...defaultValues,
  //     ]);
  //   }
  // };

  const handleFolderChange = (folder: string) => {
    setFolder(folder);
  };

  const handleSubmit = async () => {
    try {
      if (folder === "") {
        setError("Please select a folder or enter a folder name");
        return;
      }

      setLoading(true);

      var task = {
        prompt: prompt.id,
        folder: folder,
        type: "id",
        input: inputValues,
        filePath: "promptDocuments/text/state_of_the_union.txt",
      };

      await runPrompt(task);

      eventbus.emit({
        type: "show_snack_bar",
        payload:
          "Submitting the task. You'll see the document under 'Recent' soon..",
      });

      eventbus.emit({ type: "refresh_docs_table", payload: undefined });
      setLoading(false);
      onClose(inputValues);
      navigate("/home");
    } catch (error: any) {
      setError(error.message);
      setLoading(false);
    }
  };

  const renderInput = (input: Input) => {
    const { name, type, question, validation, options, defaultValue } = input;
    switch (type) {
      case "text":
        return (
          <TextField
            key={name}
            label={question}
            variant="filled"
            defaultValue={defaultValue}
            fullWidth
            required={validation.required}
            onChange={(e) => handleInputChange(name, e.target.value)}
          />
        );
      case "textarea":
        return (
          <TextField
            key={name}
            fullWidth
            label={question}
            variant="filled"
            required={validation.required}
            size="small"
            multiline
            rows={4}
            onChange={(e) => handleInputChange(name, e.target.value)}
          />
        );
      case "url":
        return (
          <TextInputField
            key={name}
            label={question}
            variant="filled"
            defaultValue={defaultValue || ""}
            fullWidth
            required={validation.required}
            type="url"
            onChange={(value, error) => {
              handleInputChange(name, value);
              setError(error);
            }}
            error={Boolean(error)}
            helperText={error}
          />
        );
      case "select":
        return (
          <FormControl
            key={name}
            fullWidth
            variant="filled"
            required={validation.required}
          >
            <InputLabel>{question}</InputLabel>
            <Select
              size="small"
              sx={{ pt: 2 }}
              label={question}
              defaultValue={defaultValue || ""}
              onChange={(e) =>
                handleInputChange(name, e.target.value as string)
              }
            >
              {options?.map((option: Option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "slider" || "number":
        return (
          <FormControl key={name} fullWidth>
            <Typography id="slider-label" gutterBottom>
              {question}
            </Typography>
            <Box display="flex">
              <Slider
                aria-labelledby="slider-label"
                step={1}
                min={0}
                max={100}
                onChange={(e, value) => handleInputChange(name, String(value))}
              />
              <Chip
                label={(() => {
                  return inputValues.find((item) => item.name === name)?.value;
                })()}
              ></Chip>{" "}
            </Box>
          </FormControl>
        );
      case "checkbox":
        return (
          <FormControl key={name} fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) =>
                      handleInputChange(
                        name,
                        e.target.checked ? "true" : "false"
                      )
                    }
                  />
                }
                label={question}
              />
            </FormGroup>
          </FormControl>
        );
      case "filePicker":
        return (
          <FormControl key={name} fullWidth>
            <Box sx={{ mb: 1 }}>
              <FilePicker
                label={question}
                onFileSelect={(file) =>
                  // handleInputChange(name, file ? file.name : "")
                  setSelectedFile(file)
                }
              />
            </Box>
          </FormControl>
        );

      // Add cases for other input types like 'markdown', 'json', etc.
    }
  };

  return (
    <Paper sx={{ p: 1 }}>
      <Box>
        {error && error !== "" && (
          <Alert sx={{ mb: 1 }} severity="error">
            {"Sorry we found an error. Please make sure all fields are filled in correctly. " +
              error}
          </Alert>
        )}
        {loading ? (
          <Box sx={{ m: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              Submitting your task to the queue..{" "}
            </Typography>
            <LinearProgress />
          </Box>
        ) : (
          <Grid container sx={{ mb: 2, p: 1 }} spacing={2}>
            {inputs.map((input) => (
              <Grid item key={input.name} xs={12}>
                {renderInput(input)}
              </Grid>
            ))}
          </Grid>
        )}

        <FolderPicker
          handleChanged={(folder) => {
            handleFolderChange(folder);
          }}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
        <Button
          variant="outlined"
          disabled={loading}
          sx={{ mr: 1 }}
          onClick={() => onClose([])}
        >
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={handleSubmit}
          startIcon={<Send />}
          variant="contained"
        >
          Send
        </Button>
      </Box>
    </Paper>
  );
};
