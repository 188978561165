import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  User,
  UserCredential
} from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

interface UserAuthContext {
  user: User | null | undefined;
  logIn: (email: string, password: string) => Promise<UserCredential>;
  signUp: (email: string, password: string) => Promise<UserCredential>;
  logOut: () => Promise<void>;
}

const userAuthContext = createContext<UserAuthContext | null>(null);


export function UserAuthContextProvider({ children }: {children: React.ReactNode}) {
  const [user, setUser] = useState<User | null>();

  function logIn(email:string, password:string) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email:string, password:string) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);
      
      if (currentuser) {
        console.log("User is signed in.");
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, logIn, signUp, logOut }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  const context = useContext(userAuthContext);
  if (context === null) {
    throw new Error("useUserAuth must be used within a UserAuthContextProvider");
  }
  return context;
}
