// Import the functions you need from the SDKs you need

import { FirebaseApp, initializeApp } from "firebase/app";
import { Auth, getAuth } from "firebase/auth";
import {
	Firestore,
	getFirestore,
} from "firebase/firestore";
import {
	Functions,
	getFunctions,
	httpsCallable,
} from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyB04JoWNaAKDdBLq8KMsgPd6iFibLKW_0c",
	authDomain: "gromptapp.firebaseapp.com",
	projectId: "gromptapp",
	storageBucket: "gromptapp.appspot.com",
	messagingSenderId: "1069385748460",
	appId: "1:1069385748460:web:c44bb69a263705f3fe7ac9",
	measurementId: "G-9544D5Z699",
};

// Initialize Firebase
export const app: FirebaseApp = initializeApp(firebaseConfig);
export const functions: Functions = getFunctions(app);
export const db: Firestore = getFirestore(app);
export const auth: Auth = getAuth(app);

export function getCallableFunction(name: string) {
	functions.region = "us-central1";
	
	const f = httpsCallable(functions, name);
	return f;
}

export const uploadFile = async (file: Blob | Uint8Array | ArrayBuffer, fileName: string) => {
	try {
	  const storage = getStorage(app);
	  const storageRef = ref(storage, `/promptDocuments/text/${fileName}`); // Specify the desired path for the uploaded file
  
	  // Upload the file
	  await uploadBytes(storageRef, file);
  
	  // Get the download URL of the uploaded file
	  const downloadURL = await getDownloadURL(storageRef);
  
	  // Return the download URL
	  return downloadURL;
	} catch (error) {
	  // Handle any errors that occur during the upload
	  console.error("Error uploading file:", error);
	  throw error;
	}
  };