import { Box, Pagination } from "@mui/material";
import {
  DocumentData,
  QueryFieldFilterConstraint,
  collection,
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { db } from "../../firebase";
import Doc from "./Doc";
const stc = require("string-to-color");

interface Sort {
  field: string;
  order: "asc" | "desc";
}

const DocsTable = ({
  search = "",
  creatorId = "",
  folder = "",
  category = "",
  command = "",
  parentDocId = "",
}: {
  search?: string;
  creatorId?: string;
  folder?: string;
  category?: string;
  command?: string;
  parentDocId?: string;
}) => {
  const [docs, setDocs] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [lastVisible, setLastVisible] = useState<DocumentData | undefined>();
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [error, setError] = useState<string>("");

  const [loading, setLoading] = useState(true);

  const [sort, setSort] = useState<Sort>({
    field: "createdAt",
    order: "asc",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const docsRef = collection(db, "docs");
      const searchConstraints: QueryFieldFilterConstraint[] = [];

      if (creatorId && creatorId !== "")
        searchConstraints.push(where("creatorId", "==", creatorId));

      if (category && category !== "")
        searchConstraints.push(where("category", "==", category));

      if (folder && folder !== "")
        searchConstraints.push(where("folder", "==", folder));

      if (command && command !== "")
        searchConstraints.push(where("command", "==", command));

      if (parentDocId && parentDocId !== "")
        searchConstraints.push(where("parentDocId", "==", parentDocId));

      let q =
        page === 1
          ? query(
              docsRef,
              orderBy("docOrder", "asc"),
              ...searchConstraints,
              limit(rowsPerPage)
            )
          : query(
              docsRef,
              orderBy("docOrder", "asc"),
              startAfter(lastVisible),
              ...searchConstraints,
              limit(rowsPerPage)
            );

      const querySnapshot = await getDocs(q);
      const snapshot = await getCountFromServer(q);
      const count = await snapshot.data().count;
      let int = Math.trunc;
      setPageCount(int(count / rowsPerPage + 1));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setDocs(data);
      setLoading(false);
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    };

    fetchData();
  }, [
    searchTerm,
    page,
    rowsPerPage,
    sort,
    creatorId,
    category,
    folder,
    command,
  ]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(1);
    setLastVisible(undefined);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Box>
      {loading && (
        <Box sx={{ p: 2 }}>
          <ContentLoader />
        </Box>
      )}

      {!loading && (
       <Box sx={{borderLeft:2,marginLeft:2,borderLeftColor:"#efefef"}}>
      
          {docs.map((doc, index) => (
            <Doc key={doc.id + index} index={index} doc={doc} />
          ))} 
          </Box>
   
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 1,
          mt: 0,
        }}
      >
        {docs.length > 0 && pageCount > 1 && (
          <Pagination
            count={pageCount}
            page={page}
            onChange={handleChangePage}
          />
        )}
      </Box>
    </Box>
  );
};

export default DocsTable;
