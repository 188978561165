import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  TextField,
  Stack,
  Link,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../../utils/UserAuthContext";
import { auth } from "../../firebase";
import robot from "../../images/robot.png";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const googleProvider = new GoogleAuthProvider();

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();

  function googleSignIn() {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (!credential) {
          throw new Error("Sorry, We can't log you in. No credential found");
        }

        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        navigate("/explore");
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        setError("Sorry, we can't log you in using google");
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/home");
    } catch (err: any) {
      setError(
        "Sorry, Please Check Your Email and Password. Or Sign Up if you are here first time" );
      console.log(err);
    }
  };

  return (
    <>
      <Box   boxShadow={3} sx={{ p: 4, mt: 3, minWidth: "300px", maxWidth: "300px" }}>
        <Typography variant="h5" sx={{ mb: 3 }}>
        🤖 Welcome Back
        </Typography>
        <Divider/>
        {error && <Alert severity="error">{error}</Alert>}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          { false && <GoogleButton
            onClick={async () => {
              try {
                await googleSignIn();
              } catch (error: any) {
                setError(
                  "Sorry, Can't Login With Google. Try again or sign up with your email"
                );
              }
            }}
            className="g-btn"
            type="dark"
          /> } 
        </Stack>
        <Box
          sx={{
            mt: 2,
            mb: 3,
            textAlign: "center",
            fontSize: "small",
          }}
        >
          <Typography variant="caption"> Sign In With Email</Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Stack direction="column" spacing={2}>
            <TextField
              type="email"
              label="Email address"
              variant="filled"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <TextField
              type="password"
              label="Password"
              variant="filled"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
            <Button variant="contained" color="primary" type="submit">
              Sign In
            </Button>
          </Stack>
        </form>
        <Box sx={{ p: 2, pt: 4,display: 'flex',
alignItems: 'center',
justifyContent: 'center' }}>
          <Typography variant="body1">New To Copilotr? &nbsp;</Typography>
          <Link component={RouterLink} to="/signup">  <Typography variant="body1">Sign Up</Typography></Link>
        </Box> 
      </Box>
    </>
  );
};

export default Login;