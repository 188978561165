import {
	Container,
	Typography,
	Button,
	Box,
	Grid,
	Paper,
	styled,
	Slide,
} from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import robots from "./robots.png";
import { Explore, Login } from "@mui/icons-material";
import GlobalNavbar from "../components/global/GlobalNavbar";
import PromptDeck from "../components/prompts/list/PromptDeck";
import React from "react";
import { useUserAuth } from "../utils/UserAuthContext";

import { isMobile } from "react-device-detect";
import { Facebook } from "react-content-loader";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	color: theme.palette.text.secondary,
}));

const LaunchPage = () => {
	const containerRef = React.useRef(null);
	const { user } = useUserAuth();
	const navigate = useNavigate();

	
	if (user) {
		console.log("User is signed in.");
		navigate("/home");
	}

	return (
		<Box sx={{ background: "transparent", minHeight: 1 }}>
			{!user && <GlobalNavbar />}
			{!user && <Box>
			<Slide
				timeout={{ enter: isMobile ? 0 : 1500 }}
				direction="down"
				in={true}
				container={containerRef.current}
				mountOnEnter
				unmountOnExit
			>
				<Box sx={{ mt: 2, borderRadius: 2 }}>
					<Container
						sx={{
							borderColor: "#efefef",
							borderRadius: 2,
						}}
					>
						<Grid container sx={{}} spacing={1}>
							<Grid item xs={12} md={5}>
								<Item
									sx={{
										boxShadow: 0,
										background: "transparent",
									}}
								>
									<Box
										component="img"
										alt="Robotic Prompts"
										sx={{ width: 0.8, m: 2 }}
										src={robots}
									/>
								</Item>
							</Grid>
							<Grid
								item
								sx={{
									boxShadow: 0,
									background: "transparent",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
								}}
								xs={12}
								md={7}
							>
								<Item
									sx={{
										boxShadow: 0,
										background: "transparent",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											p: 0,
										}}
									>
										<Box>
											<Typography
												variant={isMobile ? "h5" : "h4"}
												sx={{ fontWeight: 600 }}
											>
												{
													"Build, Deploy & Run AI Agents To Get Things Done!"
												}
											</Typography>

											<Box sx={{ mb: 4, mt: 2 }} />
											<Typography
												variant="body1"
												color="black"
											>
												{
													"Copilotr is the world's first AI Agent Ops platform to build, deploy and manage AI Agents to automate complex tasks. With the context of your internal or external data sets, these agents will 10x > 100x > 1000x your productivity with by leveraging large language models safely & securely."
												}
											</Typography>

											<Box
												sx={{
													p: 0,
													mt: 3,
													mb: 3,
												}}
											>
												<Button
													variant="contained"
													startIcon={<Login />}
													sx={{
														mt: 2,
														mr: 2,
														borderRadius: 2,
													}}
													component={Link}
													to={
														user
															? "/home"
															: "/signup"
													}
													size="large"
												>
													{!user
														? "Start An Agent"
														: "My Dashboard"}
												</Button>
												<Button
													variant="outlined"
													startIcon={<Explore />}
													sx={{
														mt: 2,
														borderRadius: 2,
													}}
													component={Link}
													to={
														user
															? "/explore"
															: "/signin"
													}
													size="large"
												>
													{!user
														? "Explore Agents"
														: "Start An Agent"}
												</Button>
											</Box>
										</Box>
									</Box>
								</Item>
							</Grid>
						</Grid>
					</Container>
				</Box>
			</Slide>
			<Slide
				timeout={{ enter: isMobile ? 0 : 1500 }}
				direction="up"
				in={true}
				container={containerRef.current}
				mountOnEnter
				unmountOnExit
			>
				<Box>
					<PromptDeck
						title="Start An Agent"
						category=""
						isSmall={false}
					/>
				</Box>
			</Slide></Box>}
			{user && <Facebook/>}
		</Box>
	);
};

export default LaunchPage;
