
import { createContext, useContext, useEffect, useState } from 'react';

type EventBusEventType = {
    type: string;
    payload: any;
  };


interface EventBusContextData {
  emit: (event: EventBusEventType) => void;
  subscribe: (type: string, callback: (payload: any) => void) => void;
  unsubscribe: (type: string, callback: (payload: any) => void) => void;
}

const EventBusContext = createContext<EventBusContextData | null>(null);

export const useEventBus = () => {
  const context = useContext(EventBusContext);
  if (!context) {
    throw new Error('useEventBus must be used within an EventBusProvider');
  }
  return context;
};

export const EventBusProvider=({children}: {children: React.ReactNode})=> {
    const [eventHandlers, setEventHandlers] = useState<Record<string, Set<(payload: any) => void>>>({});
  
    const emit = (event: EventBusEventType) => {
      const handlers = eventHandlers[event.type];
      if (handlers) {
        handlers.forEach((handler) => handler(event.payload));
      }
    };
  
    const subscribe = (type: string, callback: (payload: any) => void) => {
      setEventHandlers((prev) => {
        const newHandlers = new Set(prev[type] || []);
        newHandlers.add(callback);
        return { ...prev, [type]: newHandlers };
      });
    };
  
    const unsubscribe = (type: string, callback: (payload: any) => void) => {
      setEventHandlers((prev) => {
        const handlers = prev[type];
        if (handlers) {
          handlers.delete(callback);
        }
        return { ...prev, [type]: handlers };
      });
    };
  
    return (
      <EventBusContext.Provider value={{ emit, subscribe, unsubscribe }}>
        {children}
      </EventBusContext.Provider>
    );
  };
  