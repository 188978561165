import TextField, { TextFieldProps } from "@mui/material/TextField";
import React, { ChangeEvent, useState } from "react";
import Validator from "../../utils/Validator";

interface TextInputFieldProps extends Omit<TextFieldProps, "onChange"> {
  type: "text" | "email" | "url";
  onChange?: (value: string, error: string | null) => void;
}

const TextInputField: React.FC<TextInputFieldProps> = ({
  type,
  onChange,
  ...rest
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    onChange?.(inputValue, validateInput(inputValue));
  };

  const validateInput = (inputValue: string) => {
    let errorMessage: string | null = null;

    switch (type) {
      case "text":
        errorMessage = Validator.validateText(inputValue);
        break;
      case "email":
        errorMessage = Validator.validateEmail(inputValue);
        break;
      case "url":
        errorMessage = Validator.validateURL(inputValue);
        break;
      // Add more cases for different input types and their validations

      default:
        break;
    }

    setError(errorMessage);
    return errorMessage;
  };

  return (
    <TextField
      {...rest}
      type={type}
      onChange={handleChange}
      error={Boolean(error)}
      helperText={error}
    />
  );
};

export default TextInputField;
