import Signup from "../components/auth/Signup";
import { Box } from "@mui/material";

const styles = {
  container: {
    marginTop: "5rem",
    display: "flex",
    justifyContent: "center",
  },
};

const SignUpPage = () => {
  return (<>
  
    <Box sx={styles.container}>
      <Signup />
    </Box></>
  );
};

export default SignUpPage;
