import React from 'react';

const NotFound = () => {
  return (
    <div>
      <img src="https://image.flaticon.com/icons/svg/565/565580.svg" alt="404 not found" width="200" height="200" />
      <h1>404 - This is not the page you are looking for.</h1>
      <h2>But don't worry, this is not the end of the world, just the end of the internet for you.</h2>
    </div>
  );
};

export default NotFound;