import { useState, useEffect } from "react";
import {
	Select,
	MenuItem,
	Box,
	FormControl,
	InputLabel,
	Typography,
	TextField,
	Chip,
	Avatar,
} from "@mui/material";
import {
	collection,
	query,
	orderBy,
	limit,
	getDocs,
	where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { FolderOutlined } from "@mui/icons-material";
import { useUserAuth } from "../../utils/UserAuthContext";

const FolderPicker = ({
	handleChanged,
}: {
	handleChanged: (folder: string) => void;
}) => {
	const [folders, setFolders] = useState<any[]>([
		{ id: "Drafts", name: "Drafts" },
	]);
	const [selectedFolder, setSelectedFolder] = useState<string>("Drafts");
	const { user } = useUserAuth();

	useEffect(() => {
		const fetchData = async () => {
			if (folders.length > 0) return;

			console.log(selectedFolder);

			const modelsCollection = collection(db, "folders");
			const modelsQuery = query(
				modelsCollection,
				where("creatorId", "==", user?.uid),
				orderBy("createdAt"),
				limit(100)
			);

			getDocs(modelsQuery)
				.then((snapshot) => {
					let items = snapshot.docs.map((doc) => {
						let item = doc.data();
						item.id = doc.id;
						return item;
					});

					if (items.length === 0) return;
					setFolders(items);
					let name = items[0].name;
					setSelectedFolder(name);
				})
				.catch((err) => {});
		};
        handleFolderChange("Drafts");
		fetchData();
	}, []);

	const handleFolderChange = (val: string) => {
        if (val==="") val="Drafts";
		setSelectedFolder(val);
		handleChanged(val);
	};

	return (
		<Box sx={{ m: 1, p: 1, pt: 2, mb: 3 }}>
			<Box display={"flex"}>
				<Avatar sx={{mr:1}}>
					<FolderOutlined/>
				</Avatar>
				<Box sx={{ flexGrow: 1 }}>
					<FormControl fullWidth>
						<InputLabel id="select-model-label">
							Save To Folder
						</InputLabel>
						<Select
							value={selectedFolder}
							labelId="select-model-label"
							label="Save To Folder"
							variant="outlined"
							size="small"
							onChange={(e) =>
								handleFolderChange(e.target.value as string)
							}
							displayEmpty
							inputProps={{ "aria-label": "Select Model" }}
						>
							<MenuItem value="" disabled>
								Select Folder
							</MenuItem>
							{folders.map((f) => (
								<MenuItem key={f.name} value={f.name}>
									{f.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>

				<TextField
					size="small"
					sx={{ ml: 1, flexGrow: 1 }}
					onChange={(e) =>
						handleFolderChange(e.target.value as string)
					}
					label={
						folders.length > 0
							? "Or Enter New Folder Name"
							: "Enter New Folder Name"
					}
					variant="outlined"
				/>
			</Box>
		</Box>
	);
};

export default FolderPicker;
