import { useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	Typography,
	Box,
	TableContainer,
	TableRow,
	TextField,
	Pagination,
	Avatar,
	Chip,
	Button,
	LinearProgress,
	Paper,
} from "@mui/material";
import Moment from "react-moment";
import {
	collection,
	getDocs,
	orderBy,
	query,
	limit,
	where,
	DocumentData,
	startAfter,
	getCountFromServer,
	QueryFieldFilterConstraint,
} from "firebase/firestore";
import { db } from "../../../firebase";
import invertColor from "../../../utils/invertColor";
import {
	PlayArrow,
} from "@mui/icons-material";
import { PromptDisplay } from "../PromptDisplay";
import ContentLoader from "react-content-loader";
const stc = require("string-to-color");

interface Sort {
	field: string;
	order: "asc" | "desc";
}

const PromptTable = ({
	category="",
	creatorId="",
	title="",
	handleClick,
}: {
	category?: string;
	creatorId?: string;
	title?:string;
	handleClick: (p:PromptDisplay) => void;
}) => {
	const [prompts, setPrompts] = useState<PromptDisplay[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [lastVisible, setLastVisible] = useState<DocumentData | undefined>();
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [error, setError] = useState<string>("");

	const [loading, setLoading] = useState(true); 
	

	const [sort, setSort] = useState<Sort>({
		field: "createdDate",
		order: "asc",
	});



	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const promptsRef = collection(db, "prompts");
			const searchConstraints: QueryFieldFilterConstraint[] = [];

			if (creatorId && creatorId!=="")
			searchConstraints.push(where("creatorId", "==", creatorId));

			if (category && category!=="")
			searchConstraints.push(where("category", "==", category));
		

			let q =
				page === 1
					? query(
							promptsRef,
							orderBy("order"),
							...searchConstraints,
							limit(rowsPerPage) 
					  )
					: query(
							promptsRef,
							orderBy("order"),
							startAfter(lastVisible),
							...searchConstraints,
							limit(rowsPerPage)
					  );

			const querySnapshot = await getDocs(q);
			const snapshot = await getCountFromServer(q);
			const count = await snapshot.data().count;
			let int = Math.trunc;
			setPageCount(int(count / rowsPerPage + 1));
			const data = querySnapshot.docs.map((doc) => ({
				id: doc.id,
				...doc.data()
			})) as PromptDisplay[];

			setPrompts(data);
			setLoading(false);
			setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
		};

		fetchData();
	}, [searchTerm, page, rowsPerPage, sort, creatorId]);
 
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
		setPage(1);
		setLastVisible(undefined);
	};

	const handleChangePage = (
		event: React.ChangeEvent<unknown> | null,
		newPage: number
	) => {
		setPage(newPage);
	};

	

	return (
		<Paper sx={{ mt: 2,p:1, border:0, boxShadow:0 }}>
	
			{loading && <Box sx={{p:2}}><ContentLoader/></Box>}

			{!loading && (
				<TableContainer sx={{ width: 1 }}>
					<Table>
						<TableBody>
							{prompts.map((prompt) => (
								<TableRow key={prompt.id}>
									<TableCell>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Avatar
												sx={{
													fontWeight: "bold",
													border: 1,
													borderColor: "#eee",
													background: "#f4f4f4",
												}}
												aria-label="recipe"
											>
												{prompt.icon}
											</Avatar>
											<Button
												variant="text"
												color="primary" 
												size="small" 
												onClick={() => {
													handleClick(prompt);
												}}
												sx={{ borderRadius: 2 }}
											>
												<Typography variant="body2" sx={{ ml: 1 }}>
												{prompt.title}
											</Typography>
											</Button>
											
										</Box>
									</TableCell>
									<TableCell
										sx={{
											display: {
												xs: "none",
												lg: "table-cell", 
											},
										}}
									> 
										 <Typography variant="body2" sx={{ ml: 1 }}>
												{prompt.description}
											</Typography>
									</TableCell>
									<TableCell>
										<Chip
											size="small"
											label={prompt.category}
											
											sx={{
												backgroundColor: stc(
													prompt.category
												),
												display: {
													xs: "none",
													md: "table-cell",
												},
												
												color: invertColor(
													stc(prompt.category),
													true
												),
											}}
										></Chip>
									</TableCell>
									<TableCell
										sx={{
											display: {
												xs: "none",
												md: "table-cell",
												minWidth: 110,
											},   
										}}
									>
										 <Moment fromNow date={new Date(prompt.createdAt.toDate())} />
									</TableCell>
									
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			)}

			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					p: 1,
					mt: 0,
				}}
			>
				{prompts.length>0 && pageCount>1 && <Pagination
					count={pageCount}
					page={page}
					onChange={handleChangePage}
				/>}
			</Box>
		</Paper>
	);
};

export default PromptTable;
