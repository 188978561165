import React from 'react';

interface XmlTextViewProps {
  xmlContent: string;
}

const XmlTextView: React.FC<XmlTextViewProps> = ({ xmlContent }) => {
  const formattedXml = formatXml(xmlContent);

  return (
    <pre>
      <code>{formattedXml}</code>
    </pre>
  );
};

function formatXml(xml: string): string {
  const xmlDoc = new DOMParser().parseFromString(xml, 'application/xml');
  const serializer = new XMLSerializer();
  return serializer.serializeToString(xmlDoc);
}

export default XmlTextView;
