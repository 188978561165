
import Login from '../components/auth/Login';
import grompts from '../images/grompts.png';
import { Box } from '@mui/material';

var sectionStyle = {
  backgroundImage: `url(${grompts})`,
}

const styles = {
  container: {
    marginTop: '5rem',
    display: 'flex',
    justifyContent: 'center',
  },
};


const SignInPage = () => {
  return (
<>  
    <Box sx={styles.container}>
      <Login />
    </Box></>
  );
};

export default SignInPage;