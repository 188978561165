import {
  createTheme
} from "@mui/material/styles";

import themeImage from "./background.png"; 

/* Generated by https://generator.ui.glass/ */
const whiteTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0a6ee6',  
      
    },
    secondary: {
      main: '#f2f3f3',  
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& h1": {
            color: "black" 
          },   
           backgroundColor:"#f7f7f7" 
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: '#111',
        },
      },
    }, 
    MuiDrawer: { 
      styleOverrides: {
        paper: {  
          backgroundColor: '#fff',
          color: '#111',  
        }

      }
    }
  }
});

export default whiteTheme;