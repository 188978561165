import { Box, Card, CardContent, Typography } from '@mui/material';
import React from 'react';

interface TextViewProps {
  textContent: string;
}

const TextView: React.FC<TextViewProps> = ({ textContent }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ maxWidth: 600 }}>
        <CardContent>
          <Typography>{textContent}</Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TextView;
