import {
  createTheme
} from "@mui/material/styles";
import colorThemeImage from "./background.jpg"; 
 
       

/* Generated by https://generator.ui.glass/ */  
const colorTheme = createTheme({  
  palette: { 
    mode: 'light', 
    primary: {
      main: '#111', 
    },
    secondary: { 
      main: '#f50057',
    },
  },
  components: { 
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "& h1": { 
            color: "black"
          },
          "& h5": {  
            color: "black" 
          }, 
          "& h4": {  
            color: "#112222" 
          }
          
        } 
      }
    }

  } 
});

export default colorTheme;