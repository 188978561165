import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./themes/App.css";

import {
	BookmarkOutlined,
	FolderOutlined,
	GroupOutlined,
	HomeOutlined,
	MenuOpen,
	SearchOutlined,
	Settings,
	SmartToyOutlined,
} from "@mui/icons-material";
import {
	Alert,
	Avatar,
	Chip,
	Container,
	InputBase,
	Snackbar,
	StyledEngineProvider,
	ThemeProvider,
	useMediaQuery,
} from "@mui/material";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Route, Routes } from "react-router-dom";
import AuthButton from "./components/auth/AuthButton";
import { PromptDisplay } from "./components/prompts/PromptDisplay";
import CreateDialog from "./components/prompts/create/CreateDialog";
import { useEventBus } from "./eventbus/eventbus";
import DocsPage from "./pages/DocsPage";
import ExplorePage from "./pages/ExplorePage";
import FoldersPage from "./pages/FoldersPage";
import HelpPage from "./pages/HelpPage";
import HomePage from "./pages/HomePage";
import LaunchPage from "./pages/LaunchPage";
import NotFound from "./pages/NotFound";
import ProfilePage from "./pages/ProfilePage";
import SignUpPage from "./pages/SignUpPage";
import SignInPage from "./pages/SingInPage";
import ToolsPage from "./pages/ToolsPage";
import blackTheme from "./themes/blackTheme";
import colorTheme from "./themes/colorTheme";
import whiteTheme from "./themes/whiteTheme";
import { useUserAuth } from "./utils/UserAuthContext";

const drawerWidth = 300;

const Search = styled("div")(({ theme }) => ({
	position: "relative",
	borderRadius: 2,
	background: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	opacity: 0.9,
	"&:hover": {
		opacity: 1,
	},
	marginLeft: 0,
	width: "100%",
	[theme.breakpoints.up("sm")]: {
		marginLeft: theme.spacing(1),
		width: "auto",
	},
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
	padding: theme.spacing(0, 2),
	height: "100%",
	position: "absolute",
	pointerEvents: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
	color: "inherit",
	"& .MuiInputBase-input": {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)})`,
		transition: theme.transitions.create("width"),
		width: "100%",
		border: 0,
		[theme.breakpoints.up("md")]: {
			width: "40ch",
			border: 0,
			"&:focus": {
				width: "50ch",
			},
		},
		[theme.breakpoints.up("xl")]: {
			width: "60ch",
			border: 0,
			"&:focus": {
				width: "80ch",
			},
		},
	},
}));

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(0)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer - 1,

	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.easeIn,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	background: "transparent",
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

export default function PromptApp() {
	const { user } = useUserAuth();
	const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");

	const theme = useTheme();
	const [open, setOpen] = React.useState(isMobile ? false : true);
	const [openSnackbar, setOpenSnackbar] = React.useState(false);
	const [snackBarMessage, setSnackBarMessage] = React.useState("");
	const [entryCount, setEntryCount] = React.useState(0);
	const [createOpen, setCreateOpen] = React.useState(false);
	const [selectedPrompt, setSelectedPrompt] = React.useState<
		PromptDisplay | undefined
	>();
	const navigate = useNavigate();
	const eventBus = useEventBus();

	const [selectedIndex, setSelectedIndex] = React.useState(0);

	useEffect(() => {
		const handleShowCreate = (payload: any) => {
			let p = payload as PromptDisplay;
			setEntryCount(entryCount + 1);
			setSelectedPrompt(undefined);
			setCreateOpen(true);
			setSelectedPrompt(p);
		};

		const handleShowSnackbar = (payload: any) => {
			setSnackBarMessage(payload);
			setOpenSnackbar(true);
		};

		eventBus.subscribe("show_create_dialog", handleShowCreate);
		eventBus.subscribe("show_snack_bar", handleShowSnackbar);

		return () => {
			eventBus.unsubscribe("show_create_dialog", handleShowCreate);
			eventBus.subscribe("show_snack_bar", handleShowSnackbar);
		};
	}, []);

	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number
	) => {
		setSelectedIndex(index);
		switch (index) {
			case 0:
				navigate("/home");
				break;
			case 1:
				navigate("/folders");
				break;
			case 2:
				navigate("/explore");
				break;
			case 3:
				navigate("/recent");
				break;
			case 4:
				navigate("/drafts");
				break;
			case 5:
				navigate("/settings");
				break;
		}
		if (isMobile) {
			setOpen(false);
		}
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider
				theme={
					user
						? isDarkModeEnabled
							? blackTheme
							: whiteTheme
						: colorTheme
				}
			>
				<CssBaseline />
				<Box sx={{ display: "flex" }}>
					<CssBaseline />
					<Snackbar
						open={openSnackbar}
						onClose={() => {
							setOpenSnackbar(false);
						}}
						autoHideDuration={6000}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
					>
						<Alert
							onClose={() => {
								setOpenSnackbar(false);
							}}
							severity="success"
							sx={{ width: "100%" }}
						>
							{snackBarMessage}
						</Alert>
					</Snackbar>
					{user && (
						<AppBar
							color="secondary"
							position="fixed"
							elevation={1}
							className={
								isDarkModeEnabled
									? "glassDarkNoShadow"
									: "glassNoShadow"
							}
							open={open}
							sx={{ boxShadow: 0, background: "transparent" }}
						>
							<Toolbar>
								<Box display="flex">
									<IconButton
										color="inherit"
										aria-label="open drawer"
										onClick={handleDrawerOpen}
										edge="start"
										sx={{
											marginRight: 2,
											...(open && { display: "none" }),
										}}
									>
										<MenuIcon />
									</IconButton>
									{!open && !isMobile && (
										<Link
											to={"/home"}
											style={{
												display: "flex",
												alignItems: "center",
												textDecoration: "none",
												marginLeft: 5,
												color: "inherit",
												marginRight: "1rem",
											}}
										>
											<Avatar className="glass">
												<Typography
													sx={{
														fontSize: "1.2rem",
														fontWeight: 700,
													}}
												>
													🤖
												</Typography>
											</Avatar>

											<Typography
												variant="h5"
												sx={{
													fontSize: "1.4rem",
													fontWeight: 400,
													display: {
														xs: "none",
														sm: "block",
													},
													ml: 1,
													mr: 1,
												}}
											>
												Copilotr
											</Typography>
											{process.env.REACT_APP_LOCAL ===
												"true" && (
												<Chip
													size="small"
													color="info"
													label="local"
												></Chip>
											)}
										</Link>
									)}
								</Box>

								<Box
									sx={{
										flex: 1,
										display: "flex",

										justifyContent: "center",
									}}
								>
									<Search
										sx={{
											borderRadius: 2,
											border: 2,
											borderColor: "#eee",
										}}
									>
										<SearchIconWrapper>
											<SearchOutlined />
										</SearchIconWrapper>
										<StyledInputBase
											placeholder="Search…"
											inputProps={{
												"aria-label": "search",
											}}
										/>
									</Search>
								</Box>
								<AuthButton />
							</Toolbar>
						</AppBar>
					)}
					{user && (
						<Drawer
							variant={isMobile ? "temporary" : "permanent"}
							sx={{ background: "transparent" }}
							anchor={isMobile?"left":undefined}
							open={open}
						>
							<DrawerHeader
								sx={{
									justifyContent: "left",
									alignContent: "left",
								}}
							>
								<Box display="flex" sx={{ width: 1 }}>
									<Typography
										noWrap
										variant="h6"
										sx={{
											flexGrow: 0,
											fontWeight: 700,
											mr: 2,
											letterSpacing: ".05rem",
											color: "inherit",
											textDecoration: "none",
										}}
									>
										<Link
											to={"/home"}
											style={{
												display: "flex",
												alignItems: "center",
												textDecoration: "none",
												color: "inherit",
											}}
										>
											<Avatar className="glass">
												<Typography
													sx={{
														fontSize: "1.2rem",
														fontWeight: 700,
													}}
												>
													🤖
												</Typography>
											</Avatar>

											<Typography
												variant="h5"
												sx={{
													fontSize: "1.4rem",
													fontWeight: 400,
													display: {
														xs: "none",
														sm: "block",
													},
													ml: 1,
												}}
											>
												Copilotr
											</Typography>
										</Link>
									</Typography>
									<Box sx={{ flexGrow: 1 }}></Box>

									<IconButton
										color="inherit"
										aria-label="open drawer"
										onClick={handleDrawerOpen}
										edge="start"
										sx={{
											marginRight: 2,
											...(open && { display: "none" }),
										}}
									>
										<MenuIcon />
									</IconButton>
									<IconButton
										color="inherit"
										sx={{
											marginRight: 2,
											...(!open && {
												display: "none",
											}),
										}}
										onClick={handleDrawerClose}
									>
										{theme.direction === "rtl" ? (
											<MenuIcon/>
										) : (
											<MenuOpen/>
										)}
									</IconButton>
								</Box>
							</DrawerHeader>

							<List
								component="nav"
								sx={{ background: "transparent" }}
								aria-label="navigation"
							>
								<ListItemButton
									selected={selectedIndex === 0}
									onClick={(event) =>
										handleListItemClick(event, 0)
									}
								>
									<ListItemIcon>
										<HomeOutlined />
									</ListItemIcon>
									<ListItemText primary="Home" />
								</ListItemButton>
								<ListItemButton
									selected={selectedIndex === 1}
									onClick={(event) =>
										handleListItemClick(event, 1)
									}
								>
									<ListItemIcon>
										<FolderOutlined />
									</ListItemIcon>
									<ListItemText primary="Folders" />
								</ListItemButton>
								<ListItemButton
									selected={selectedIndex === 2}
									onClick={(event) =>
										handleListItemClick(event, 2)
									}
								>
									<ListItemIcon>
										<SmartToyOutlined />
									</ListItemIcon>
									<ListItemText primary="Agents" />
								</ListItemButton>
								<ListItemButton
									selected={selectedIndex === 3}
									onClick={(event) =>
										handleListItemClick(event, 3)
									}
								>
									<ListItemIcon>
										<GroupOutlined />
									</ListItemIcon>
									<ListItemText primary="Creators" />
								</ListItemButton>
								<ListItemButton
									selected={selectedIndex === 4}
									onClick={(event) =>
										handleListItemClick(event, 4)
									}
								>
									<ListItemIcon>
										<BookmarkOutlined />
									</ListItemIcon>
									<ListItemText primary="Bookmarks" />
								</ListItemButton>
								<ListItemButton
									selected={selectedIndex === 5}
									onClick={(event) =>
										handleListItemClick(event, 5)
									}
								>
									<ListItemIcon>
										<Settings />
									</ListItemIcon>
									<ListItemText primary="Settings" />
								</ListItemButton>
							</List>
						</Drawer>
					)}
					<Container
						sx={(theme) => ({
							minHeight: "100vh",
							pt: 3,
							[theme.breakpoints.down("sm")]: {
								width: 1,
							},
							[theme.breakpoints.up("sm")]: {
								width: 0.99,
							},
							[theme.breakpoints.up("md")]: {
								width: 0.99,
							},
							[theme.breakpoints.up("lg")]: {
								width: 0.98,
							},
						})}
					>
						<DrawerHeader />
						<CreateDialog
							key={entryCount}
							open={createOpen}
							promptToShow={selectedPrompt}
							handleClose={() => {
								setCreateOpen(false);
								setSelectedPrompt(undefined);
								setEntryCount(entryCount + 1);
							}}
						/>

						<Routes>
							<Route path="/" element={<LaunchPage />} />
							<Route path="/home" element={<HomePage />} />
							<Route path="/folders" element={<FoldersPage />} />
							<Route
								path="/folders/:folderId"
								element={<DocsPage />}
							/>
							<Route path="/explore" element={<ExplorePage />} />
							<Route path="/profile" element={<ProfilePage />} />
							<Route path="/settings" element={<ToolsPage />} />
							<Route path="/help" element={<HelpPage />} />
							<Route path="/signin" element={<SignInPage />} />
							<Route path="/signup" element={<SignUpPage />} />
							<Route element={<NotFound />} />
						</Routes>
					</Container>
				</Box>
			</ThemeProvider>
		</StyledEngineProvider>
	);
}
