import {
	AppBar,
	Avatar,
	Badge,
	Container,
	CssBaseline,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { Link, Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import AuthButton from "../auth/AuthButton";
import robot from "../../images/robot.png";
import { useUserAuth } from "../../utils/UserAuthContext";
import { SmartToy } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

const GlobalNavbar = () => {
	const { user } = useUserAuth();

	const StyledTypography = styled(Typography)({
		flexGrow: 1,
	});
	const isDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');

	return (
		<Box  sx={{ flexGrow: 1 }}>
			<CssBaseline />
			<AppBar className="glass" position="fixed" elevation={1}>
				<Toolbar> 
					<Typography
						noWrap
						variant="h6"
						sx={{
							flexGrow: 0,
							fontWeight: 700,
							mr: 5,
							letterSpacing: ".05rem",
							color: "inherit",
							textDecoration: "none",
						}}
					>
						<Link
							to={"/"}
							style={{
								display: "flex",
								alignItems: "center",
								textDecoration: "none",
								color: "inherit",
							}}
						>
							<Avatar >
								<Typography
									sx={{ fontSize: "1.4rem", fontWeight: 700 }}
								>
									🤖
								</Typography>
							</Avatar>
							&nbsp;
							<Typography
								sx={{
									fontSize: "1.4rem",
									fontWeight: 700, 
									color:"#111"
								}}
							>
								Copilotr
							</Typography>  
										 
						</Link>
					</Typography>

					<Box sx={{ flexGrow: 1 }}></Box>
					<AuthButton />
				</Toolbar>{" "}
			</AppBar>
		</Box>
	);
};

export default GlobalNavbar;
