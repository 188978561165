import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Alert, IconButton, Paper, Typography } from "@mui/material";
import { useState } from "react";
import PromptTable from "../list/PromptTable";
import { DynamicForm } from "./DynamicForm";
import { PromptDisplay } from "../PromptDisplay";
import { ArrowLeft } from "@mui/icons-material";

export default function CreateDialog({
	open,
	promptToShow,
	handleClose,
}: {
	open: boolean;
	promptToShow: PromptDisplay | undefined;
	handleClose: () => void;
}) {
	const [selectedPrompt, setSelectedPrompt] = useState<
		PromptDisplay | undefined
	>(undefined);

	const [error, setError] = useState<string>("");
	const [loading, setLoading] = useState(false);
	const [value, setValue] = React.useState("1");


	React.useEffect(() => {
		if (selectedPrompt === promptToShow) {
			setSelectedPrompt(undefined);
		}
		setSelectedPrompt(promptToShow);
	}, [promptToShow]);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			onClose={handleClose}
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={open} timeout={100}>
				<Paper
					sx={(theme) => ({
						position: "absolute" as "absolute",
						top: "50%",
						opacity: 0.5,  
						left: "50%", 
						minHeight: 400,
						
						maxHeight: 600,
						transform: "translate(-50%, -50%)",
						borderRadius: 2,
						[theme.breakpoints.down("md")]: {
							width: 0.9,
						},
						[theme.breakpoints.up("md")]: {
							width: 0.7,
						}, 
						boxShadow: 0, 
						p: 1,
					})}
				>
					<Box sx={{ elevation: 1 }} display="flex">
						{selectedPrompt && (
							<IconButton
								onClick={() => {
									setSelectedPrompt(undefined);
									setError("");
								}}
								sx={{
									m: 2,
									border: 1,
									maxHeight: "30px",
									maxWidth: "30px",
								}}
							>
								<ArrowLeft />{" "}
							</IconButton>
						)}
						<Box>
							<Typography sx={{ p: 1 }} variant="h6">
								{selectedPrompt
									? (selectedPrompt.icon || "🤖") +
									  " " +
									  selectedPrompt.title
									: "🤖 Start An Agent"}
							</Typography>
							{selectedPrompt && (
								<Typography sx={{ m: 1 }} variant="caption">
									{selectedPrompt.description}
								</Typography>
							)}
						</Box>
					</Box>

					{error && error !== "" && (
						<Alert severity="error">{error}</Alert>
					)}

					{selectedPrompt ? (
						<Box
							sx={{
								borderRadius: 2,
								width: "100%",
								maxHeight: 500,
								overflow: "auto",
							}}
						>
							<DynamicForm
								prompt={selectedPrompt}
								open={selectedPrompt !== undefined}
								onClose={() => {
									setSelectedPrompt(undefined);
									handleClose();
								}}
							/>
						</Box>
					) : (
						<Box
							sx={{
								borderRadius: 1, 
								width: "100%",
								maxHeight: 500,
								overflow: "auto",
							}}
						>
							<PromptTable  
									handleClick={(p: PromptDisplay | undefined) => {
										setSelectedPrompt(p);
									} } 						/>
						</Box>
					)}
				</Paper>
			</Fade>
		</Modal>
	);
}
