import React from 'react';
import GlobalNavbar from '../components/global/GlobalNavbar';
import { Container } from '@mui/material';

const HelpPage = () => {
  return (
    <Container/>
  );
};

export default HelpPage;