import { createTheme } from "@mui/material/styles";

/* Generated by https://generator.ui.glass/ */
const blackTheme = createTheme({
	palette: {
		mode: "dark",
		primary: {
			main: "rgb(19, 155, 240)", // '#172539',
			contrastText: "#fff",
		},

		secondary: {
			main: "#fff",
			contrastText: "#111",
		},
		background: {
			default: "rgba(24,20,20,0.86)",
		},
		color: {
			default: "#fff",
		},
	}
});

export default blackTheme;
