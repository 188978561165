import { Box, Button } from "@mui/material";
import React, { useRef } from "react";

interface FilePickerProps {
  label: string;
  onFileSelect: (file: File | null) => void;
}


const FilePicker: React.FC<FilePickerProps> = ({ label, onFileSelect }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    onFileSelect(file || null);
  };

  const handleUploadFile = async () => {
    // if (fileInputRef.current?.files?.length) {
    //   const file = fileInputRef.current.files[0];
    //   try {
    //     const downloadURL = await uploadFile(file, file.name);
    //     console.log("File uploaded. Download URL:", downloadURL);
    //     // Handle the download URL as needed
    //   } catch (error) {
    //     console.error("Error uploading file:", error);
    //     // Handle the error as needed
    //   }
    // }
  };

  return (
    <Box>
      <Button variant="outlined" onClick={handleButtonClick}>
        {label}
      </Button>
      <input
        type="file"
        accept=".txt"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileSelect}
      />
      <Button variant="contained" onClick={handleUploadFile}>
        Upload
      </Button>
    </Box>
  );
};

export default FilePicker;
