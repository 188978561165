import { Box, Fade, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import {
	QueryConstraint,
	collection,
	limit,
	onSnapshot,
	orderBy,
	query,
	where
} from "firebase/firestore";
import ContentLoader from "react-content-loader";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useEventBus } from "../../eventbus/eventbus";
import { db } from "../../firebase";
import { useUserAuth } from "../../utils/UserAuthContext";
import PromptTable from "../prompts/list/PromptTable";
import Doc from "./Doc";
const stc = require("string-to-color");

const DocsRecentLIst = ({
	folder = "",
	creatorId = "",
}: {
	folder: string | undefined;
	creatorId: string | undefined;
}) => {
	const [docs, setDocs] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);
	const { logOut, user } = useUserAuth();
	const navigate = useNavigate();

	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [entryCount, setEntryCount] = useState(0);

	const eventBus = useEventBus();

	useEffect(() => {
		const handleCreateDone = (payload: any) => {
			setEntryCount(entryCount + 1);
		};
		eventBus.subscribe("refresh_docs_table", handleCreateDone);

		return () => {
			eventBus.unsubscribe("refresh_docs_table", handleCreateDone);
		};
	}, []);

	useEffect(() => {
		if (user) {
			setLoading(true);

			const constraints: QueryConstraint[] = [];

			constraints.push(where("parentDocId", "==", null));
			if (creatorId && creatorId !== "")
				constraints.push(where("creatorId", "==", creatorId));
			if (folder && folder !== "")
				constraints.push(where("folder", "==", folder));
			const q = query(
				collection(db, "docs"),
				orderBy("createdAt", "desc"),
				...constraints,
				limit(rowsPerPage)
			);

			const unsubscribe = onSnapshot(q, (querySnapshot) => {
				const newdocs: any = [];
				querySnapshot.forEach((doc: any) => {
					newdocs.push({ id: doc.id, ...doc.data() });
				});
				setDocs(newdocs);
				setLoading(false);
			});
			return () => unsubscribe();
		}
	}, [entryCount, user]);
 
	if (docs.length > 0) {
		return (
			<Box>
				<Box sx={{ mb: 1, mt: 2, ml: 2 }}>
					<Typography variant="h6">Recent</Typography>
				</Box>
				{docs.map((doc, index) => (
					<Doc key={doc.id} index={index} doc={doc} />
				))}
			</Box>
		);
	} else if (loading)
		return (
			<Box sx={{ p: 2 }}>
				<ContentLoader />
			</Box>
		);
	else
		return (
			<Fade
				timeout={{ enter: isMobile ? 0 : 1500 }}
				in={true}
				mountOnEnter
				unmountOnExit
			>
				<Box>
					<Box sx={{ mb: 1, mt: 5, ml: 2 }}>
						<Typography variant="caption">
							You don't have any documents yet. Run an agent to create a document first!
						</Typography>
					</Box>
					<PromptTable
						category={""}
						handleClick={(p) => {
							eventBus.emit({
								type: "show_create_dialog",
								payload: p,
							});
						}}
					/>
				</Box>
			</Fade>
		);
};

export default DocsRecentLIst;
