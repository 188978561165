import { getCallableFunction } from "../firebase";
import prompts from "./prompts/prompts.json";
import models from "./models.json";

export const loadInit = async () => {
  const createPrompt = getCallableFunction("createPrompt");
  const createModel = getCallableFunction("createModel");

  for (const m of models.models) {
    createModel(m);
  }

  let order=0;
  for (const p of prompts.prompts) {
    order++;
    createPrompt({
      order,
      ...p}); 
  }
};
