import { useNavigate } from "react-router";
import { useUserAuth } from "../../utils/UserAuthContext";
import {
	Box,
	Button,
	IconButton,
	ListItemText,
	Menu,
	Typography,
	MenuItem,
	Stack,
	Divider,
	Paper,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
	PersonAdd,
	Lock,
	AccountCircle,
	PersonPin,
	AddCircle,
	Key,
	Badge,
	BadgeOutlined,
	LockOutlined,
} from "@mui/icons-material";
import { useState } from "react";
import { useEventBus } from "../../eventbus/eventbus";
import { isMobile } from "react-device-detect";

const AuthButton = () => {
	const { logOut, user } = useUserAuth();
	const navigate = useNavigate();
	const eventBus = useEventBus();

	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = async () => {
		try {
			await logOut();
			navigate("/");
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			{!user && (
				<Box display="flex">
					<Button
						component={RouterLink}
						variant="outlined"
						color="primary"
						startIcon={<Key />}
						sx={{
							mr: 1,
						}}
						to="/signin"
					>
						<Typography
							sx={{
								display: {
									xs: "none",
									sm: "inline-block",
								},
							}}
						>
							{isMobile ? "" : "Log In"}
						</Typography>
					</Button>
					&nbsp;
					<Button
						component={RouterLink}
						variant="contained"
						sx={{
							
							borderRadius: 2,
						
						}}
						to="/signup"
						startIcon={<PersonAdd />}
					>
						<Typography
							sx={{
								display: {
									xs: "none",
									sm: "inline-block",
								},
							}}
						>
							{isMobile ? "" : "Get Started"}
						</Typography>
					</Button>
				</Box>
			)}
			{user && (
				<Stack direction="row">
					<Button
						onClick={() => {
							eventBus.emit({
								type: "show_create_dialog",
								payload: undefined,
							});
						}}
						sx={{
							borderRadius: 2,
							width: 100,
							display: {
								xs: "none",
								lg: "block",
							},
						}}
						color="primary"
						variant="text"
					>
						<Box sx={{ display: "flex" }}>
							<AddCircle /> 
							&nbsp;Create
						</Box>
					</Button>

					<IconButton
						color="inherit"
					
						sx={{
							mt:1,
							display: {
								xs: "block",
								md: "none",
							},
						
						}}
						onClick={() => {
							eventBus.emit({
								type: "show_create_dialog",
								payload: undefined,
							});
						}}
					>
						<AddCircle />
					</IconButton>
					<IconButton
						sx={{
							display: {
								xs: "block",
							},
							mt:1,
						}}
						onClick={handleClick}
						color="inherit"
					>
						<AccountCircle />
					</IconButton>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={()=>{navigate("/profile")}}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: "visible",
								filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
								mt: 1.5,
								"& .MuiAvatar-root": {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								"&:before": {
									content: '""',
									display: "block",
									position: "absolute",
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: "background.paper",
									transform: "translateY(-50%) rotate(45deg)",
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{
							horizontal: "right",
							vertical: "top",
						}}
						anchorOrigin={{
							horizontal: "right",
							vertical: "bottom",
						}}
					>
						<MenuItem onClick={handleClose}>
							<Box sx={{}}>
								<Box display="flex" alignItems="center">
									<BadgeOutlined fontSize="small" />
									<ListItemText sx={{ml:1,}} primary={"Profile"} />
								</Box>
								<Typography
									variant="caption"
									sx={{
										display: {
											xs: "none",
											sm: "inline-block",
										},
										ml:1,
									}}
								>
									{user.displayName || user.email}
								</Typography>
							</Box>
						</MenuItem>
						<Divider />
						<MenuItem onClick={handleLogout}>
							<Box display="flex" alignItems="center">
								<LockOutlined fontSize="small" />
								<ListItemText sx={{ml:1,}} primary="Sign out" />
							</Box>
						</MenuItem>
					</Menu>
				</Stack>
			)}
		</>
	);
};

export default AuthButton;
