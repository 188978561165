//./components/Editor
import React, { memo, useEffect, useRef } from "react";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import { EDITOR_TOOLS } from "./EditorJs/Editor";
import markdownit from "markdown-it/lib";
import { Box } from "@mui/material";



//props
type Props = {
  data?: any;
  onChange(val: OutputData): void;
  format:string;
  holder: string;
};
const EditorJSEditor = ({ data, onChange,format, holder }: Props) => {
  //add a reference to editor
  const ref = useRef<EditorJS>();
  const converter = new markdownit();
  //initialize editorjs
  useEffect(() => {
    //initialize editor if we don't have a reference
    if (!ref.current) {
      const editor = new EditorJS({
        holder: holder,
        tools: EDITOR_TOOLS,
        async onChange(api, event) {
          const data = await api.saver.save();
          onChange(data);
        }
      });

      editor.isReady.then(() => {
        const content=(format==="markdown")?converter.render(data):data;
       editor.blocks.renderFromHTML(content);
      });
  
      ref.current = editor;
    }
    //add a return function handle cleanup
    return () => {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }
    };
  }, []);
  return <Box sx={{maxHeight:600,overflow:"auto"}}><div id={holder} /></Box>;
};
export default memo(EditorJSEditor);