import { ArrowLeft } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import DocsRecentLIst from "../components/docs/DocsRecentList";
import { useUserAuth } from "../utils/UserAuthContext";
import Validate from "../components/auth/Validate";

export default function DocsPage() {
	const { folderId } = useParams<{ folderId: string | undefined }>();
    const navigate =useNavigate();
	const {user}=useUserAuth();

	return (
		<Box>
			<Validate/>
            <Box display="flex">
                <IconButton onClick={()=>{navigate("/folders")}}  sx={{mt:1, border:1}}><ArrowLeft/> </IconButton> 
			<Typography   
				noWrap
				variant="body1"
				sx={{
					flexGrow: 1,
					pt: 2,
					fontWeight: 700,
					pl: 2,
					letterSpacing: ".05rem",
					color: "inherit",
					textDecoration: "none",
				}}
			>
				Folders
			</Typography></Box>
			<Typography
				noWrap
				variant="h4"
				sx={{
					flexGrow: 1,
					pt: 2,
					fontWeight: 700,
					pl: 2,
					letterSpacing: ".05rem",
					color: "inherit",
					textDecoration: "none",
				}}
			>
				{folderId}
			</Typography>
			<Box>
				<DocsRecentLIst creatorId={user?.uid}  key={folderId as string} folder={folderId as string} />
			</Box>
		</Box>
	);
}
