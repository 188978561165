import {
	Avatar,
	Box,
	Button,
	Fade,
	Paper,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import DocsRecentLIst from "../components/docs/DocsRecentList";
import PromptDeck from "../components/prompts/list/PromptDeck";
import { useEventBus } from "../eventbus/eventbus";
import { useUserAuth } from "../utils/UserAuthContext";
import Validate from "../components/auth/Validate";

const HomePage = () => {
	const eventBus = useEventBus();
	const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");
	const { user } = useUserAuth();

	return (
		<>
			<Box sx={{ p: 0 }}>
			<Validate/>
				<Typography
					noWrap
					variant="h4"
					sx={{
						flexGrow: 1,
						pt: 2,
						pl: 2,
						fontWeight: 700,
						letterSpacing: ".05rem",
						color: "inherit",
						textDecoration: "none",
					}}
				>
					Home
				</Typography>
			</Box>
			<Fade
				timeout={{ enter: isMobile ? 0 : 1000 }}
				in={true}
				mountOnEnter
				unmountOnExit
			>
				<Paper 
					
					sx={{ mt: 2, pt: 2, p:2, boxShadow:0, borderRadius: 2 }}
				>
					<Box display="flex">
						<Avatar sx={{ ml: 2, display: "flex" }}></Avatar>
						<Button
							sx={{
								ml: 2,
								mr: 2,
								pl: 2,
								textTransform: "none",
								flexGrow: 1,
								justifyContent: "left",
								display: "flex",
							}}
							size="small"
							variant="outlined"
							onClick={() => {
								eventBus.emit({
									type: "show_create_dialog",
									payload: undefined,
								});
							}}
						>
							<Typography variant="body1">
								What do you want to create?
							</Typography>
						</Button>
					</Box>
					<PromptDeck title="" category="" isSmall={true} />
				</Paper>
			</Fade>

			<Box sx={{ mb: 5 }}>
				<DocsRecentLIst key="recent" creatorId={user?.uid} folder="" />
			</Box>

			<Box
				sx={{ marginTop: "auto", flexDirection: "row", flexGrow: 1 }}
			></Box>

			
		</>
	);
};

export default HomePage;
