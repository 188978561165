import Typography from "@mui/material/Typography";
import { PromptDisplay } from "../PromptDisplay";
import { Chip, Avatar, IconButton, Box, Paper } from "@mui/material";
import Moment from "react-moment";
import {
	BookmarkAddOutlined,
	PlayArrow,
	ShareOutlined,
} from "@mui/icons-material";
import { useState } from "react";

import { useEventBus } from "../../../eventbus/eventbus";
import invertColor from "../../../utils/invertColor";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../../utils/UserAuthContext";


const stc = (str:string)=> {
	
		var hash = 0;
		for (var i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		var colour = '#';
		for (var i = 0; i < 3; i++) {
			var value = (hash >> (i * 8)) & 0xFF;
			colour += ('00' + value.toString(16)).substr(-2);
		}
		return colour;
	
}

export default function PromptCard({
	prompt,
	itemId,
}: {
	prompt: PromptDisplay;
	itemId: string;
}) {
	const [expanded, setExpanded] = useState(false);

	const eventBus = useEventBus();
	const { user } = useUserAuth();
	const navigate = useNavigate();

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	const bgcolor = stc(prompt.category);
	const fgcolor = invertColor(bgcolor, true);
	return (
		<Box
		component={Paper}
		className="card" 
			sx={{
				display: "flex",
				width: 180,
				height: 220,
				borderRadius: 2,
				m: 1,
				p: 1,
				flexDirection: "column",
			}}
		>
			<Box 
				display="flex"
				sx={{ width: 1,  opacity: 0.8 }}
			>
				<Avatar
					sx={{
						color: fgcolor,
						fontWeight: "bold",
					}}
					aria-label="recipe"
				>
					{prompt.icon}
				</Avatar>
				<Box sx={{ flexGrow: 1 }} />
				<Chip
					label={prompt.category}
          size="small"
					sx={{
						mt: 1,
						opacity: 0.9,
						background: bgcolor,
						color: fgcolor,
					}}
				></Chip>
			</Box>

			<Box sx={{ marginBottom: "auto", mt: 2 }} justifyContent="center">
				<Typography variant="caption"  component="span">
					John Doe &nbsp;
				</Typography>
				<Typography variant="caption" component="p">
					{" "}
					<Moment
						fromNow
						date={new Date(prompt.createdAt?.toDate())}
					/>
				</Typography>
				<Typography
					variant="body2"
					sx={{ fontWeight: "bold" }}
					component="p"
				>
					{prompt.title}
				</Typography>
			</Box>
			<Box sx={{ marginTop: "auto" }} justifyContent="center">
				<Box
					display="flex"
					justifyContent="center"
					sx={{
						pt: 0,
						flex: 1,
						mt: 1,
						maginTop: "auto",
						borderRadius: 4,
					}}
				>
					<IconButton
						onClick={() => {
							user
								? 
									 eventBus.emit({
										type: "show_create_dialog",
										payload: prompt,
								  })
								

								: navigate("/signup");
						}}
						aria-label="run"
					>
						<PlayArrow />
					</IconButton>
					<IconButton aria-label="add to favorites">
						<BookmarkAddOutlined />
					</IconButton>
					<IconButton aria-label="share">
						<ShareOutlined />
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
}

