import { Container, Button, Box, Typography, Paper } from "@mui/material";
import { loadInit } from "../init/initLoader";
import { useState } from "react";

const ToolsPage = () => {


  const [loading,setLoading]=useState(false);
  const [done,setDone]=useState(false);
  const [error,setError]=useState<any>(undefined);

  const handleClickSave = () => {
    setLoading(true)
    try {
    loadInit();
    // Perform action here
    setDone(true);
    }
    catch (e:any) {

      console.log(e); 
      setError(e); 
    }

  };


  return (
    /* Modify Selected Text To Use MUI 5.0 instead of React Bootstrap */

    <Container >
      <h1>Dev Tools</h1>
      <Typography variant="caption">
        Should be disabled in production
      </Typography>
 

      <Paper sx={{ p: 2,mt:4,}}>
        <h4> For Initial Setup Only </h4>

        {(loading===false) && <Button
          sx={{ m: 2 }}
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={handleClickSave}
        >
          Load Initial Data
        </Button>}

        {done && <Typography variant="subtitle1" sx={{ color: "text.secondary" }}> The data seems loaded correctly. Please check the prompts table or just goto home and see if you can see prompts</Typography>} 
        {error && <Typography variant="subtitle1" color="red" sx={{ color: "text.secondary" }}> There seems to be an error: {error} </Typography>} 
      </Paper>
   
   
    </Container>
  );
};

export default ToolsPage;
