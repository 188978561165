import * as React from "react";

import { Box, Typography } from "@mui/material";
import Validate from "../components/auth/Validate";
import PromptTable from "../components/prompts/list/PromptTable";
import { useEventBus } from "../eventbus/eventbus";

const ExplorePage = () => {
	const [value, setValue] = React.useState("1");
	const eventBus = useEventBus();

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<>
			<Validate />
			<Box>
				<Typography
					noWrap
					variant="h4"
					sx={{
						flexGrow: 1,
						pt: 2,
						fontWeight: 700,
						pl: 2,
						letterSpacing: ".05rem",
						color: "inherit",
						textDecoration: "none",
					}}
				>
					Explore Agents
				</Typography>
			</Box>

			<PromptTable
				category={""}
				handleClick={(p) => {
					eventBus.emit({
						type: "show_create_dialog",
						payload: p,
					});
				}}
			/>
		</>
	);
};

export default ExplorePage;
