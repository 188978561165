import * as React from "react";
import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FolderList from "../components/folders/FolderList";
import { useNavigate } from "react-router-dom";
import Validate from "../components/auth/Validate";

const FoldersPage = () => {
	const navigate = useNavigate();

	return (
		<>
		<Validate/>
			<Box>
				<Typography
					noWrap
					variant="h4"
					sx={{
						flexGrow: 1,
						pt: 2,
						fontWeight: 700,
						pl: 2,
						letterSpacing: ".05rem",
						color: "inherit",
						textDecoration: "none",
					}}
				>
					Folders
				</Typography>
				<Box>
					<FolderList
						search=""
						handleClick={(folder) => {
            
						}}
					/>
				</Box>
			</Box>
		</>
	);
};

export default FoldersPage;
