import {
	DeleteOutlineOutlined,
	EditOutlined,
	ExpandMore,
	Restore,
} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Box,
	Button,
	Chip,
	Divider,
	Fade,
	LinearProgress,
	Paper,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { getCallableFunction } from "../../firebase";
import { useUserAuth } from "../../utils/UserAuthContext";
import invertColor from "../../utils/invertColor";
import ImageDisplay from "../commen/ImageDisplay";
import TextView from "../commen/TextView";
import XmlTextView from "../commen/XmlTextView";
import EditorJSEditor from "../editors/EditorJSEditor";
import AlertDialog from "./AlertDialog";
import DocsTable from "./DocsTable";
const stc = require("string-to-color");
export default function RenderDocument({
  index,
  doc,
}: {
  index: any;
  doc: any;
}) {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const isDarkModeEnabled = useMediaQuery("(prefers-color-scheme: dark)");

  //Function to update the document
  const handleUpdate = (doc: any) => {
    const updateDocById = getCallableFunction("updateDocById");

    setLoading(true);
  };

  //Function to delete the document
  const handleDelete = (doc: any) => {
    const deleteDocById = getCallableFunction("deleteDocById");
    deleteDocById({ id: doc.id });
    navigate("/home");
  };

  const isMyDoc = (doc: any) => {
    return user && doc.creatorId === user.uid;
  };

  const parseData = (data: any) => {
    try {
      console.log(data);
      return JSON.parse(data);
    } catch {
      return {
        time: 1550476186479,
        blocks: [
          {
            type: "paragraph",
            data: {
              text: data,
            },
          },
        ],
        version: "2.8.1",
      };
    }
  };

  return (
    <Box sx={{ mt: 2 }}>
      <AlertDialog
        isOpen={alert}
        title="Do you really want to delete the document?"
        message="Please confirm deletion"
        onConfirm={() => {
          handleDelete(doc);
          setAlert(false);
        }}
        onCancel={() => {
          setAlert(false);
        }}
      />
      <Fade
        timeout={{ enter: user ? 500 * index : 0 }}
        in={true}
        mountOnEnter
        unmountOnExit
      >
        <Box
          key={doc.id}
          sx={{
            elevation: 0,
            boxShadow: 0,
            borderRadius: 1,
          }}
        >
          <Accordion
            sx={{
              elevation: 0,
              boxShadow: 0,
            }}
            key={doc.id}
            onChange={(e, expanded) => {
              setExpanded(expanded);
            }}
            defaultExpanded={false}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Stack
                display="flex"
                sx={{
                  width: 1,
                }}
              >
                <Typography variant="caption">
									<Moment
										fromNow
										date={new Date(doc.createdAt.toDate())}
									/>{" "}
									In {doc.folder}
                </Typography>
                <Box
                  display="flex"
                  sx={{
                    width: 1,
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        border: 1,
                      }}
                    >
                      {doc.icon || "🤖 "}
                    </Avatar>
                    <Typography
                      variant="body1"
                      sx={{
                        flexGrow: 1,
                        ml: 1,
                      }}
                    >
											{doc.promptTitle} {" > "}{" "}
											{doc.card?.title ??  doc.title}
                    </Typography>
                  </Box>

                  <Chip
                    size="small"
                    label={
                      !doc.category || doc.category === ""
                        ? "No Category"
                        : doc.category
                    }
                    sx={{
                      background: stc(doc.category),
											color: invertColor(
												stc(doc.category),
												true
											),
                    }}
                  />
                </Box>
                {doc.status === "pending" && <LinearProgress sx={{ m: 2 }} />},
                {doc.status === "processingChild" && <LinearProgress sx={{ m: 2 }} />}
              </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              {doc.status !== "pending" ? (
                <Box
                  sx={{
                    borderRadius: 0,
                    mb: 1,
                    overflow: "auto",
                  }}
                >
                  {expanded && (
                    <Paper
											className={
												isDarkModeEnabled
													? "glassDark"
													: "glass"
											}
                      sx={{
                        borderRadius: 0,
                        ml: 0,
                        mr: 0,
                      }}
                    >
                      {isMyDoc(doc) && (
                        <Box sx={{ p: 1 }}>
                          <Divider />
                          <Box
                            display="flex"
                            sx={{
                              borderRadius: 0,
                              opacity: 0.8,
                              mb: 1,
                              mt: 1,
                            }}
                          >
                            <Typography
                              variant="caption"
                              sx={{
                                m: 1,
                                display: {
                                  xs: "none",
                                  md: "table-cell",
                                },
                              }}
                            ></Typography>
														<Box
															display="flex"
															sx={{ flexGrow: 1 }}
														/>
                            <Button
                              sx={{ ml: 2 }}
                              onClick={() => {
                                /* save the draft to a project folder*/
																console.log(
																	"save the draft"
																);
                              }}
															startIcon={
																<Restore />
															}
                              variant="text"
                              size="small"
                            >
                              REVISE
                            </Button>
                            <Button
                              sx={{ ml: 2 }}
                              onClick={() => {
                                /* save the draft to a project folder*/
                                setAlert(true);
																console.log(
																	"delete the draft"
																);
                              }}
															startIcon={
																<DeleteOutlineOutlined />
															}
                              variant="text"
                              size="small"
                            >
                              DELETE
                            </Button>
                            <Button
                              sx={{
                                ml: 2,
                                mr: 2,
                              }}
                              onClick={() => {
                                /* save the draft to a project folder*/
																console.log(
																	"edit the document"
																);
                              }}
															startIcon={
																<EditOutlined />
															}
                              variant="text"
                              size="small"
                            >
                              EDIT
                            </Button>
                          </Box>
                          <Divider />{" "}
                        </Box>
                      )}

                      <Box>
												{doc.display !== "none" &&
													doc.doctype === "html" && (
                          <EditorJSEditor
                            data={doc.content}
                            format="html"
                            onChange={() => {}}
                            holder={doc.id}
                          />
                        )}

                        {doc.display !== "none" &&
													doc.doctype ===
														"markdown" && (
                            <EditorJSEditor
                              data={doc.content}
                              format="markdown"
                              onChange={() => {}}
                              holder={doc.id}
                            />
                          )}
                        {doc.display !== "none" &&
													doc.doctype === "image" && (
														<ImageDisplay
															imageUrl={
																doc.content
															}
														/>
													)}
												{doc.display !== "none" && doc.display !== "children" &&
                          doc.doctype === "text" && (
														<TextView
														textContent={
																doc.content
															}
														/>
                          )}
												{doc.display !== "none" && doc.display !== "children" &&
                          doc.doctype === "xml" && (
														<XmlTextView
														xmlContent={
																doc.content
															}
														/>
                          )}
                        {
                          <Box>
														<DocsTable
															parentDocId={doc.id}
														/>
                          </Box>
                        }
                      </Box>
                    </Paper>
                  )}
                </Box>
              ) : (
                <Box sx={{ p: 4 }}>
                  <Typography sx={{ mb: 2 }} variant="body2">
										Please wait, generating something
										magical for you.. And you know all
										magical things take time, right? 💛🪄
                  </Typography>
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Fade>
    </Box>
  );
}
