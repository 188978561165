class Validator {
    static validateText(value: string): string | null {
      // Add text validation logic here
      return null;
    }
  
    static validateEmail(value: string): string | null {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailPattern.test(value)) {
        return "Please enter a valid email address";
      }
      return null;
    }
  
    static validateURL(value: string): string | null {
      const urlPattern = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
      if (!urlPattern.test(value)) {
        return "Please enter a valid URL";
      }
      return null;
    }
  }

  export default Validator; 